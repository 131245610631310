import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  header,
  hero,
  heroText,
  mobileHeader,
  mobileHero,
  mobileHeroText,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFImage
            w="100%"
            src={mobileHeader}
            alt="Todak Todak hero text"
            zIndex={98}
          />
          <CFView textCenter column center w="100%" h="33vh">
            <CFView column center white style={{ fontSize: 23 }} ph="5%">
              {/* <CFImage
                w="85%"
                src={mobileHeroText}
                alt="Todak Todak hero text"
                zIndex={98}
              /> */}
              <CFText>
                Todak Todak is now taking online orders! Try our #1 Best Seller
                -{' '}
                <CFText color="#C3915F" bold>
                  Onion Creamy Bonless Chicken
                </CFText>{' '}
                today!
              </CFText>
            </CFView>
            <CFView mt="15px" mb="5px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="50vw"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
          center
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFImage
            w="100%"
            maxWidth="1400px"
            src={header}
            alt="Todak Todak hero text"
            zIndex={98}
          />
          <CFView w="100%" column justifyCenter alignStart h="640px">
            <CFView column center ml="50px" mb="30px">
              <CFImage
                h="21.5vw"
                maxHeight="305px"
                src={heroText}
                alt="Todak Todak hero text"
                zIndex={98}
              />
              <CFView mv="30px">
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
