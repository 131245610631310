import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C3915F" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Todak Todak Facebook"
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/todak-todak-vancouver">
              <CFImage w="40px" pr="10px" src={yelp} alt="Todak Todak Yelp" />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Todak Todak Zomato"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/todakchicken/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Todak Todak Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C3915F" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Todak Todak Facebook"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/todak-todak-vancouver">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Todak Todak Yelp"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Todak Todak Zomato"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/todakchicken/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Todak Todak Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
